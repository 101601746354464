<template>
  <div v-if="cursorInfo && cursorInfo.totalCount > 0" :class="theme" pagination>
    <span :class="{invisible: now === 0}" class="left">
      <button :disabled="now === 0" @click="prevDoubleAngleBtn">
        <svg-double-angle />
      </button>
      <button :disabled="now === 0" @click="$emit('change', now - 1)">
        <svg-angle />
      </button>
    </span>
    <a v-for="cursor in cursors" :key="cursor" :class="{active: cursor === now}" @click="$emit('change', cursor)">{{ cursor + 1 }}</a>
    <span :class="{invisible: now === last}" class="right">
      <button :disabled="now === last" @click="$emit('change', now + 1)">
        <svg-angle />
      </button>
      <button :disabled="now === last" @click="nextDoubleAngleBtn">
        <svg-double-angle />
      </button>
    </span>
  </div>
</template>

<script>
import SvgAngle from '@shared/graphics/svg-angle.vue';
import { range } from '@shared/utils/commonUtils';
import Specific from '@shared/types/Specific';
import SvgDoubleAngle from '@/views/graphics/svg-double-angle.vue';

export default {
  name: 'Pagination',
  components: { SvgAngle, SvgDoubleAngle },
  props: {
    info: Specific,
    chunkSize: { type: Number, required: true },
    showSize: { type: Number, default: 5 },
    theme: { String: Number, default: 'dark' },
  },
  computed: {
    cursorInfo() {
      return /** @type{Paging<unknown>} */ this.info;
    },
    now() {
      return this.cursorInfo.currentCursor;
    },
    last() {
      return Math.ceil(this.cursorInfo.totalCount / this.chunkSize) - 1;
    },
    start() {
      return Math.max(0, Math.min(this.now - Math.floor((this.showSize - 1) / 2), this.last - this.showSize + 1));
    },
    end() {
      return Math.min(this.last, this.start + this.showSize - 1);
    },
    cursors() {
      return range(this.start, this.end);
    },
  },
  methods: {
    prevDoubleAngleBtn() {
      const { now } = this;
      if (now - 10 <= 0) return this.$emit('change', 0);
      this.$emit('change', now - 10);
    },
    nextDoubleAngleBtn() {
      const { now, last } = this;
      if (now + 10 >= last) return this.$emit('change', last);
      this.$emit('change', now + 10);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[pagination] { .tc;
  button { .wh(20); .vam; border: none;.bgc(transparent);
    &[disabled='disabled'] {cursor: not-allowed}
    &:not([disabled='disabled']) {cursor: pointer}
    svg { .w(11);
      .stroke-target { stroke: #777; }
    }
    @{hover-press} {
      svg {
        .stroke-target { stroke: #fff; }
      }
    }
  }
  button + button { .ml(10); }
  a { .vam; .ib; .mh(24); .rel; .p(0, 2); .c(#eee); .o(0.4);
    @{hover-press} { .o(1); }
    &.active { .o(1);
      &:after {.cnt; .abs; .lb; .wh(100%, 1); .bgc(#fff);}
    }
  }
  a + a { .ml(0); }
  .left button { .t-r(90deg) }
  .right button { .t-r(-90deg) }
  .invisible { .o(0.2); }

  &.dark {
    button {
      svg {
        .stroke-target { stroke: @c-title-black; }
      }
      @{hover-press} {
        svg {
          .stroke-target { stroke: black; }
        }
      }
    }
    a { .c(@c-text-dark); .o(0.4);
      @{hover-press} { .o(1); }
      &.active { .o(1);
        &:after {.cnt; .abs; .lb; .wh(100%, 1); .bgc(black);}
      }
    }
  }
  &.coaching {
    button { .wh(24);
      &:hover {.bgc(rgba(#a4a3ae, 0.1)); .br(6);}
      &[disabled='disabled'] {
        &:hover {.bgc(unset);}
      }
      svg {
        .stroke-target { stroke: #787781; }
      }
      @{hover-press} {
        svg {
          .stroke-target { stroke: #787781; }
        }
      }
    }
    a { .wh(32, 32);.fs(14);font-weight: 400;.c(#a4a3ae);.lh(30);.p(0);cursor: pointer; .br(4); .o(1);
      @{hover-press} {.bgc(#f2f5ff);}
      &.active {.c(#2c81ff);.bgc(#f2f5ff);
        &:after {.hide}
      }
    }
  }
}
</style>
