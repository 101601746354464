<template>
  <ve-table :table-data="loading ? skeletonValue : value" :columns="_columns" :row-key-field-name="_rowKey" :checkbox-option="checkboxOption" :sort-option="sortOption" custom-table :class="[`theme-${theme}`]" :data-loading="loading" />
</template>

<script>
export default {
  name: 'CoTable',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: 'rowKey',
    },
    checkboxWidth: {
      type: Number,
      default: 50,
    },
    theme: {
      type: String,
      default: 'default',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    renderLoadingCell: {
      type: [Function, undefined],
      default: ({ column }, h) => h('div', { attrs: { class: ['cell-loading', column?.field].join(' '), 'data-column': column?.field } }),
    },
    skeletonSize: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      checkboxOption: {
        selectedRowKeys: [],
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          this.changeSelectedRowKeys(selectedRowKeys);
          this.$emit('select', { row, isSelected, selectedRowKeys });
        },
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          this.changeSelectedRowKeys(selectedRowKeys);
          this.$emit('select-all', { isSelected, selectedRowKeys });
        },
      },
    };
  },
  computed: {
    _rowKey() {
      return this.rowKey;
    },
    _columns() {
      const prependRows = this.checkbox ? [{ field: '', key: 'z', type: 'checkbox', title: '', align: 'center', width: this.checkboxWidth }] : [];
      if (this.columns?.length === 0 && this.syntheticColumns?.length >= 1) return [...prependRows, ...this.syntheticColumns];
      return [...prependRows, this.columns];
    },
    columnKeys() {
      return this.$slots.default.filter(child => child?.context).map(child => child?.componentOptions?.propsData?.field);
    },
    syntheticColumns() {
      const children = this.$slots.default.filter(child => child?.context);
      const columns = children.map(child => {
        const { field, align, title, sort, width } = child?.componentOptions?.propsData ?? {};
        return {
          field,
          align: align ?? 'left',
          key: child?.data?.key ?? '',
          title: title ?? '-',
          renderBodyCell: this.loading ? this.renderLoadingCell : child?.componentOptions?.propsData?.render,
          sortBy: sort,
          width,
        };
      });
      return columns;
    },
    sortOption() {
      return { sortChange: this.sortChange.bind(this) };
    },
    skeletonValue() {
      const columnDummies = {};
      this.columnKeys.forEach(k => columnDummies[k] = '-');
      return Array.from({ length: this.skeletonSize }).map((_, i) => ({ [this.rowKey]: i, ...columnDummies }));
    },
  },
  methods: {
    sortChange(params) {
      this.$emit('sort', params);
    },
    changeSelectedRowKeys(keys) {
      this.checkboxOption.selectedRowKeys = keys;
    },
    deselectedAll() {
      this.checkboxOption.selectedRowKeys = [];
      this.$emit('deselect-all', { isSelected: false, selectedRowKeys: [] });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[custom-table] {
  .ve-table-header-th {}
  .ve-table-sort {display: inline-block;.rel;.wh(16, 16);.ml(5);.c(#bfbfbf);.pointer;.fs(14);text-align: center;float: left;.mt(3)}
  .ve-table-sort-icon {.abs;
  }
  .icon-vet-sort-top-arrow {.bg('/img/coaching/sort-arrow-up.svg');.wh(6, 5);.t(1);
    &.active {.bg('/img/coaching/sort-arrow-up-active.svg')}
  }
  .icon-vet-sort-bottom-arrow {.bg('/img/coaching/sort-arrow-down.svg');.wh(6, 5);.t(9);
    &.active {.bg('/img/coaching/sort-arrow-down-active.svg')}
  }
  &.theme-default {
    table.ve-table-content { border-collapse: collapse;border-spacing: 0;.min-w(100%);}
    .ve-table-header {.h(45);.bgc(#f2f5ff);.c(#658df2);.fs(14);.lh(20.72);border-top: solid 1px #c6d3ff; border-bottom: solid 1px #c6d3ff;}
    .ve-table-header-th {font-weight: 400;}
    .ve-table-body-tr {.h(60);border-bottom: solid 1px #ebebeb;
      &:hover {.bgc(rgba(164, 163, 174, .1));}
    }
    .ve-table-body-td {.c(#787781);.fs(14);.noto;font-weight: 400;.lh(20.72);}
    .ve-table-body-td > em {.c(#191919);font-style: normal;}
    .ve-checkbox-content {.rel;user-select: none;}
    .ve-checkbox-input {user-select: none;-webkit-appearance: none;.mr(10);}
    .ve-checkbox-content .ve-checkbox-inner {.abs;.wh(20, 20);.lt(0, 0)}
    .ve-checkbox-content:not(.ve-checkbox-checked) .ve-checkbox-inner {.bgc(#fff);border: 1px solid #d5d5de;}
    .ve-checkbox-content.ve-checkbox-checked .ve-checkbox-inner {.bgc(#fff);.bg('/img/coaching/table-checkbox.svg');}
    .cell-loading {.wh(100%, 18);.bgc(#ebebf0);}
    &[data-loading='true'] {
      .ve-checkbox-content .ve-checkbox-inner {.o(0);}
      .ve-checkbox-content {.wh(20, 20);.bgc(#ebebf0);.block;}
    }
  }
}
</style>
