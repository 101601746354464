<template>
  <span :class="[colorClass]" status-badge>{{ statusText }}</span>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    colorClass() {
      return 'blue';
    },
    statusText() {
      return '-';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[status-badge] {.p(2.11, 6, 2.11, 6);.br(50);.noto;font-weight: 700;.fs(12);.lh(17.76);white-space: nowrap;
  &.blue {.bgc(#eaeaff);.c(#2f50f8);}
  &.green {.bgc(#eaffea);.c(#219653);}
  &.red {.bgc(#ffeaea);.c(#f00);}
  &.yellow {.bgc(#fff3ea);.c(#ff7a00);}
  &.gray {.bgc(#ededed); .c(#787781);}
  &.purple {.bgc(#f1eaff); .c(#8300ff);}
}
</style>
