<template>
  <th>
    <slot>-</slot>
  </th>
</template>

<script>
export default {
  name: 'TableColumn',
  props: {
    field: {
      type: String,
      default: ''
    },
    align: {
      // center | left | right
      type: String,
      default: 'left'
    },
    title: {
      type: String,
      default: ''
    },
    // https://vuejs.org/v2/guide/render-function.html
    // ({row, column, rowIndex), createElement) =>
    render: {
      type: [Function, undefined],
      default: undefined,
    },
    sort: {
      // undefined | '' | asc | desc
      type: [String, undefined],
      default: undefined
    },
    width: {
      type: [Number, undefined],
      default: undefined
    }
  },
};
</script>